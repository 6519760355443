import { makeNoopPromiseLock, makePromiseLock } from "@warrenio/utils/promise/promiseLock";
import sleep from "@warrenio/utils/promise/sleep";
import { HttpResponse, http as mswHttp } from "msw";
import { mapValues } from "remeda";
import * as debug from "../../debugConfig.ts";
import { objectHash } from "../../utils/hash.ts";
import { withGlobalSeed } from "../../utils/random.ts";
import { getMockDb } from "../db.ts";
import { mockApiActiveHeader } from "../ids.ts";
import { randomFloatBetween, randomLowerAlpha, randomLowerAlphaNumeric } from "../randomTypes.ts";
import type { StandardRequest } from "./StandardRequest.ts";
import { countRequest, matchRequest } from "./mockRequestMatcher.ts";
import { getResponseFromError } from "./responseError.ts";

type StandardResponse = Response | undefined;

const requestLock = debug.requestsInSerial ? makePromiseLock() : makeNoopPromiseLock();

/** Wrapper for MSW handler factories that does standard pre/post-request processing. */
export const http: typeof mswHttp = mapValues(
    mswHttp,
    (originalFactory): typeof originalFactory =>
        (path, handler) =>
            originalFactory(path, async function requestWrapper(r, ...args) {
                type HandlerRequest = Parameters<typeof handler>[0];
                type HandlerResponse = ReturnType<typeof handler>;

                async function callOriginal() {
                    // WORKAROUND: use `as` – too many generic type arguments to handle the request type nicely in TypeScript
                    return (await handler(r as HandlerRequest, ...args)) as StandardResponse;
                }

                countRequest(r);

                const result = await requestLock(async () => await processRequest(r, callOriginal));

                if (result instanceof Response) {
                    result.headers.set(mockApiActiveHeader, "1");
                    result.headers.set("x-warren-correlation-id", generateCorrelationId(r));
                }

                // eslint-disable-next-line @typescript-eslint/return-await -- ESLint bug
                return result as HandlerResponse;
            }),
);

async function processRequest(r: StandardRequest, callOriginal: () => Promise<StandardResponse>) {
    await delayRequest(r);

    const mockResponse = await getMockResponse(r);
    if (mockResponse !== undefined) {
        return mockResponse;
    }

    try {
        return await callOriginal();
    } catch (e) {
        const response = getResponseFromError(e);
        if (response) {
            console.error("Mock API: sending error response", e);
            return response;
        }

        if (!(e instanceof Error)) {
            throw e;
        }

        console.error("Mock API: unhandled exception", e);
        return HttpResponse.json(
            { message: e.message, stack: e.stack },
            { status: 500, statusText: "Unhandled mock handler exception" },
        );
    }
}

async function getMockResponse(r: StandardRequest) {
    const mock = matchRequest(r);
    if (mock !== undefined) {
        console.debug("Mocking request: %o (%s)", r.request.url, r.request.method);
        return await mock;
    }

    switch (getMockDb().options.variant) {
        case "unauthorized": {
            const message = "Invalid authentication credentials";
            return HttpResponse.json({ message }, { status: 403 });
        }
    }
}

async function delayRequest(r: StandardRequest) {
    const method = r.request.method.toUpperCase();
    const delay = method === "GET" ? debug.requestDelay : debug.mutationRequestDelay;
    if (delay) {
        const randomDelay = randomFloatBetween(delay * 0.6, delay * 1.3);
        await sleep(randomDelay);
    }
}

function generateCorrelationId(r: StandardRequest): string {
    const seed = objectHash(`${r.countKey}:${r.count}`);
    // Use a deterministic seed to ensure the same correlation ID for the same request in stories
    // (which affects Chromatic snapshot rendering of eg. error blocks)
    return withGlobalSeed(seed, () => `${randomLowerAlpha(3)}-${randomLowerAlphaNumeric()}`);
}
